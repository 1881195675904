import { IMAGE_OPTIONS } from "@/libs/const";
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";

export default {
    data: () => ({
        isLoading: false,
        items: [],
        page: 1,
        totalPage: 1,
        filters: {},
        sum: {},
        imageOptions: [...IMAGE_OPTIONS],
        updatingGoods: {},
    }),
    computed: {},
    watch: {
        page() {
            this.getList()
        },
        filters: {
            handler() {
                this.page = 1
                this.getList()
            },
            deep: true
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        getStorageLocation(locations) {
            if (!locations) {
                return ''
            }
            const locationSplit = locations.split(';')
            return locationSplit.join('<br>')
        },
        getList: debounce(function () {
            let filters = { ...this.filters }
            if (filters.sku) {
                let valueSpl = filters.sku.split('@') || ['']
                filters = { ...filters, sku: valueSpl[0] }
            }
            if (filters.keywords) {
                let valueSpl = filters.keywords.split('@') || ['']
                filters = { ...filters, keywords: valueSpl[0] }
            }
            httpClient.post('/material-list', { ...filters, page: this.page }).then(({ data }) => {
                this.totalPage = data.totalPage
                this.items = [...data.rows]
                this.sum = { ...data.sum }
            })
        }, 1000),

        async downloadExcel() {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true
            const filename = 'hang-hoa-cua-hang.xlsx'

            try {
                await this.downloadExcelFile('/material-list-export', { ...this.filters }, filename)
                this.isLoading = false
            } catch (e) {
                this.isLoading = false
            }
        },

        onFilterChange(filter) {
            this.filters = { ...this.filters, [filter.name]: filter.value }
        },
        onSortChange(sort) {
            this.filters = { ...this.filters, ...sort }
        },
    }
}