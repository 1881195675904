<template>
    <div>
        <v-simple-table fixed-header height="calc(100vh - 145px)" class="table-padding-2">
            <template v-slot:default>
                <thead class="v-data-table-header">
                    <tr>
                        <th>
                            <SelectMaterialWarehouse :label="$t('labels.warehouse_1')" :placeholder="$t('labels.warehouse_1')" name="id_material_warehouse"
                                sort-name="material_warehouse_code" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                                @onSort="onSortChange" />
                        </th>
                        <th role="columnheader" style="min-width: 100px">
                            <InputFilter :label="$t('labels.customer')" :placeholder="$t('labels.customer')" name="company_name"
                                sort-name="company_name" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                                @onSort="onSortChange" />
                        </th>
                        <th role="columnheader" style="min-width: 100px">
                            <InputFilter :label="$t('labels.goods_name')" :placeholder="$t('labels.goods_name')" name="name"
                                sort-name="name" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                                @onSort="onSortChange" />
                        </th>
                        <th role="columnheader" style="min-width: 100px">
                            <InputFilter :label="$t('labels.goods_description')"
                                :placeholder="$t('labels.goods_description')" name="description" sort-name="description"
                                has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <th role="columnheader" style="min-width: 100px">
                            <InputFilter :label="$t('labels.size')" :placeholder="$t('labels.size')" name="size"
                                sort-name="size" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                                @onSort="onSortChange" />
                        </th>
                        <th role="columnheader" style="min-width: 100px">
                            <SelectFilter :options="imageOptions" :label="$t('labels.image')"
                                :placeholder="$t('labels.image')" name="image" sort-name="image" has-sort
                                :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <th role="columnheader" style="min-width: 100px">
                            <InputFilter :label="$t('labels.sku')" :placeholder="$t('labels.sku')" name="sku"
                                sort-name="sku" :default-value="filters.sku" has-sort :sorting="filters.sort_by"
                                @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <th role="columnheader" style="min-width: 100px">
                            <InputFilter :label="$t('labels.barcode')" :placeholder="$t('labels.barcode')"
                                name="code" :default-value="filters.customer_goods_barcode"
                                sort-name="code" has-sort :sorting="filters.sort_by"
                                @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <th role="columnheader" style="min-width: 100px">
                            <InputFilter :label="$t('labels.position')" :placeholder="$t('labels.position')"
                                name="storage_location" sort-name="storage_location" has-sort :sorting="filters.sort_by"
                                @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <th role="columnheader" style="min-width: 100px">
                            <InputFilter :label="$t('labels.basket')" :placeholder="$t('labels.basket')"
                                name="basket_location" sort-name="basket_location" has-sort :sorting="filters.sort_by"
                                @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <th role="columnheader" style="min-width: 100px">
                            <InputFilterFromTo :label="$t('labels.receiving')" :placeholder="$t('labels.receiving')"
                                name="receiving" sort-name="receiving" has-sort :sorting="filters.sort_by"
                                @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <th role="columnheader" style="min-width: 100px">
                            <InputFilterFromTo :label="$t('labels.wait_pickup')" :placeholder="$t('labels.wait_pickup')"
                                name="wait_pickup" sort-name="wait_pickup" has-sort :sorting="filters.sort_by"
                                @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <th role="columnheader" style="min-width: 100px">
                            <InputFilterFromTo :label="$t('labels.pickedup')" :placeholder="$t('labels.pickedup')"
                                name="pickedup" sort-name="pickedup" has-sort :sorting="filters.sort_by"
                                @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <th role="columnheader" style="min-width: 100px">
                            <InputFilterFromTo :label="$t('labels.available')" :placeholder="$t('labels.available')"
                                name="available" sort-name="available" has-sort :sorting="filters.sort_by"
                                @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in items" :key="`${item.id}_${item.id_material_warehouse}`" class="text-center">
                        <td>{{ item.material_warehouse_code }}</td>
                        <td>{{ item.company_name }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.size }}</td>
                        <td>
                            <ImageViewer v-if="item.image" :url="item.image" />
                        </td>
                        <td>{{ item.sku }}</td>
                        <td>{{ item.code }}</td>
                        <td>
                            <div v-html="getStorageLocation(item.storage_location)"></div>
                        </td>
                        <td>
                            <div v-html="getStorageLocation(item.basket_location)"></div>
                        </td>
                        <td>{{ formatNumber(item.receiving) }}</td>
                        <td>{{ formatNumber(item.wait_pickup) }}</td>
                        <td>{{ formatNumber(item.pickedup) }}</td>
                        <td>{{ formatNumber(item.available) }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-row class="pt-3">
            <v-col cols="12" md="4">
                <div class="d-flex align-center">
                    <div>
                        <div class="d-flex table-footer-count">
                            <div class="mr-2">{{ $t('labels.sku') }}: 
                                <b>{{ formatNumber(sum.sumSku) }}</b>
                            </div>
                            <div class="mr-2">{{ $t('labels.avai_sku') }}: 
                                <b>{{ formatNumber(sum.sumAvaiSku) }}</b>
                            </div>
                        </div>
                        <div class="d-flex table-footer-count mt-1">
                            <div class="mr-2">
                                {{ $t('labels.available_quantity_1') }}:
                                <b>{{ formatNumber(sum.sumAvailable) }}</b>
                            </div>
                            <div class="mr-2">
                                {{ $t('labels.receiving_quantity') }}:
                                <b>{{ formatNumber(sum.sumReceiving) }}</b>
                            </div>
                            <div class="mr-2">
                                {{ $t('labels.export_quantity') }}:
                                <b>{{ formatNumber(+sum.sumWaitPickup + +sum.sumPickedup) }}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="4">
                <v-pagination v-model="page" :length="totalPage" :total-visible="4"></v-pagination>
            </v-col>
            <v-col cols="12" md="4" class="text-right">
                <!--<v-btn color="info" @click="downloadExcel">
                    <v-icon>mdi-download</v-icon>
                    Excel
                </v-btn>-->
            </v-col>
        </v-row>
    </div>
</template>

<script>
import list from "@/components/material/mixins/list";
export default {
    name: "ListDesktop",
    components: {
        ImageViewer: () => import("@/components/common/ImageViewer"),
        SelectFilter: () => import('@/components/table/SelectFilter'),
        SelectMaterialWarehouse: () => import('@/components/table/SelectMaterialWarehouse'),
        InputFilter: () => import('@/components/table/InputFilter'),
        InputFilterFromTo: () => import('@/components/table/InputFilterFromTo'),
    },
    mixins: [list]
}
</script>

<style scoped></style>
